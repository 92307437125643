import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import visitIcon from '../assets/svg/visit.svg';
import deleteIcon from '../assets/svg/delete.svg';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';
function generateAlias(str) {
    /*str = str.toLowerCase();
    str = str.replace(/\s\s+/g, ' ');
    str = str.replace(/ /g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\,/g, '-');
    str = str.replace(/š/g, 's');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/ž/g, 'z');*/
    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    return str;
}
class CarsPage extends Component {
    constructor(props) {
        super(props);
        this.deletePage = this.deletePage.bind(this);
        this.fetchPages = this.fetchPages.bind(this);
        this.crawl = this.crawl.bind(this);


        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.fetchPages();


    }


    crawl() {
        if (!localStorage.token) {
            return;
        }

        this.setState({
            _loading: true
        }, () => {
            fetch('https://showroom-api.novamedia.agency/admin/updateCars', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            }).then((res) => {
                this.setState({
                    _loading: null
                })
                this.fetchPages()
            });

        })



    }

    fetchPages() {
        if (!localStorage.token) {
            return;
        }

        fetch('https://showroom-api.novamedia.agency/admin/fetch/cars', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })


    }

    deletePage(id) {
        if (!localStorage.token) {
            return;
        }

        fetch('https://showroom-api.novamedia.agency/admin/delete/cars/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            },
        }).then((res) => this.fetchPages())
    }
    render() {

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <header>
                                <button className="button" onClick={() => {
                                    if (!this.state._loading){
                                        this.crawl();
                                    }
                                }}> {this.state._loading ? <div className="lds-dual-ring"></div> : null } Mit mobile.de synchronisieren</button>
                            </header>
                        </Col>
                    </Row>
                </Container>


                <Container fluid className="table">

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>Alle Fahrzeuge</h3>
                        </Col>
                    </Row>
                    <Row className="table-head">
                        <Col lg="1">

                            <span className="name"></span>
                        </Col>
                        <Col lg="6">

                            <span className="name">AUTOMARKE</span>
                        </Col>
                        <Col lg="3">

                            <span className="name">PREIS</span>
                        </Col>

                        <Col lg="2" className="actions">

                            <span className="name">OPTIONEN</span>
                        </Col>

                    </Row>
                    {
                        this.state.items.map((item, idx) => {
                            return (
                                <Row className="table-row table-image-row">
                                    <Col lg="1">
                                        <img className="table-image" src={'https://showroom-api.novamedia.agency/' + (item.images && item.images[0])} />
                                    </Col>

                                    <Col lg="6">
                                        <span className="value">{item.title}</span>
                                    </Col>
                                    <Col lg="3">
                                        <span className="value">{item.price && item.price.grs && item.price.grs.localized}</span>
                                    </Col>

                                    <Col lg="2" className="actions">
                                        <a target="_blank" href={`https://showroom.gmbh/autos/${generateAlias(item.title)}/${item._id}`}><Isvg src={visitIcon} /></a>
                                        <button onClick={() => this.deletePage(item._id)}><Isvg src={deleteIcon} /></button>

                                    </Col>

                                </Row>

                            )
                        })
                    }

                </Container>



            </div>
        );
    }
}

export default Page(CarsPage);