import React from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import Image from './fields/image';
import Html from './fields/html';
import Select from './fields/select';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

const renderHtmlField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Html
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )


const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Image
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )




const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">{props.disableCategory ? 'Stellenangebote' : 'Service'}</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>
                            <Col lg="9">
                                <Container>
                                    <Row>
                                        {!props.disableCategory ?
                                            <Col lg="12" className="input-wrap">
                                                <Field
                                                    name="category"
                                                    component={renderSelectField}
                                                    label={"Kategorien"}
                                                    placeholder="Wählen Sie eine Kategorie aus">

                                                    <option value={0}>Werkstatt</option>
                                                    <option value={1}>Fahrzeugaufbereitung</option>
                                                    <option value={2}>Smart Repair</option>
                                                </Field>
                                            </Col>

                                            :

                                            null
                                        }


                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="name"
                                                component={renderTextField}
                                                label={"Titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        {/* <Col lg="12" className="input-wrap">
                                            <Field
                                                name="name1"
                                                component={renderTextField}
                                                label={"Roter Titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


    </Col>*/}
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="content"
                                                component={renderHtmlField}
                                                label={"Kurze Beschreibung"}
                                                placeholder="Geben Sie eine kurze Beschreibung ein"

                                            ></Field>


                                        </Col>

                                        {/*
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="content"
                                                component={renderHtmlField}
                                                label={"Tekst"}
                                                placeholder="Unesite tekst vijesti"

                                            ></Field>

</Col>*/}

                                    </Row>
                                </Container>
                            </Col>

                            <Col lg="3" className="input-wrap">

                                <Field
                                    name="image"
                                    component={renderImageField}

                                ></Field>
                            </Col>


                        </Row>
                    </Container>
                </Col>


                <Col lg="12">
                    <button className="button">Speichern</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
