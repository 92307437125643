import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Page from '../containers/page';


import SlideForm from '../components/forms/slideForm';
import NewsForm from '../components/forms/newsForm';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import PopupForm from '../components/forms/popupForm';

class AddPagePage extends Component {
    constructor(props) {
        super(props);
        this.addPage = this.addPage.bind(this);

        this.state = {

        };
    }

    addPage(data) {
        console.log(data);

        fetch('https://showroom-api.novamedia.agency/admin/updateOne/' + this.props[0].match.params.collection + '/' + this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.props[0].history.push('/collection/' + this.props[0].match.params.collection)
        })
    }

    componentDidMount() {
        if (this.props[0].match.params.id != 'new') {
            fetch('https://showroom-api.novamedia.agency/admin/fetchOne/' + this.props[0].match.params.collection + '/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    initialValues: result
                })
                console.log(result);
            })

        }
    }


    render() {
        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            {this.props[0].match.params.id !== 'new' ? <h3>Seite bearbeiten</h3> : <h3>Seite hinzufügen</h3>}
                        </Col>

                    </Row>
                    {this.state.initialValues ?
                        this.props[0].match.params.collection != 'slides' ?
                            this.props[0].match.params.collection == 'services' || this.props[0].match.params.collection == 'jobs' ?
                                <NewsForm disableCategory={this.props[0].location.pathname.indexOf('jobs') !== -1 ? true : null} collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />
                                :
                                this.props[0].match.params.collection == 'popup' ?
                                    <PopupForm collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />

                                    :
                                    null
                            :
                            <SlideForm collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />


                        :

                        this.props[0].match.params.collection != 'slides' ?
                            this.props[0].match.params.collection == 'services' || this.props[0].match.params.collection == 'jobs' ?
                                <NewsForm disableCategory={this.props[0].location.pathname.indexOf('jobs') !== -1 ? true : null} collection={this.props[0].match.params.collection} onSubmit={this.addPage} />
                                :
                                this.props[0].match.params.collection == 'popup' ?
                                    <PopupForm collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />

                                    :
                                    null
                            :
                            <SlideForm collection={this.props[0].match.params.collection} onSubmit={this.addPage} />

                    }
                </Container>


            </div>
        );
    }
}

export default Page(AddPagePage);