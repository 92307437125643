import React from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import Image from './fields/image';
import Html from './fields/html';
import Gallery from './fields/gallery';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )





const renderHtmlField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Html
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Image
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

    const renderGalleryField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
        lang,
        multilang
    }) => (
    
            <Gallery
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                lang={lang}
                multilang={multilang}
                {...input}
            />
        )

const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Abschnitt Nr. 1</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>

                            <Col lg="12" className="input-wrap">

                                <Field
                                    name="section[0].image"
                                    component={renderImageField}

                                ></Field>
                            </Col>


                            <Col lg="9">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="section[0].block1"
                                                component={renderTextField}
                                                label={"Fläche"}
                                                placeholder=""

                                            ></Field>


                                        </Col>

 <Col lg="12" className="input-wrap">
                                            <Field
                                                name="section[0].block2"
                                                component={renderTextField}
                                                label={"Jahre Erfahrung"}
                                                placeholder=""

                                            ></Field>


                                        </Col>

                                         <Col lg="12" className="input-wrap">
                                            <Field
                                                name="section[0].block3"
                                                component={renderTextField}
                                                label={"Kundenzufriedenheit"}
                                                placeholder=""

                                            ></Field>


                                        </Col>

                                    </Row>
                                </Container>
                            </Col>
{/*
                            <Col lg="3" className="input-wrap">

                                <Field
                                    name="section[0].image2"
                                    component={renderImageField}

                                ></Field>
                            </Col>

                            <Col lg="9">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="section[0].title2"
                                                component={renderTextField}
                                                label={"Titel"}
                                                placeholder="Geben Sie einen Abschnittstitel ein"

                                            ></Field>


                                        </Col>


                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="section[0].content2"
                                                component={renderHtmlField}
                                            ></Field>

                                        </Col>

                                    </Row>
                                </Container>
</Col>*/}



                        </Row>
                    </Container>
                </Col>

                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Über uns</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>
                            <Col lg="3">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="infoblock[0].name"
                                                component={renderTextField}
                                                label={"Name"}
                                                placeholder="Bitte geben Sie einen Namen ein"

                                            ></Field>
                                        </Col>


                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="infoblock[0].image"
                                                component={renderImageField}

                                            ></Field>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>

                            <Col lg="3">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="infoblock[1].name"
                                                component={renderTextField}
                                                label={"Name"}
                                                placeholder="Bitte geben Sie einen Namen ein"

                                            ></Field>
                                        </Col>

                                       {/* <Col lg="12" className="input-wrap">

                                            <Field
                                                name="infoblock[1].content"
                                                component={renderTextareaField}
                                                label={"Beschreibung"}
                                                placeholder="Bitte geben Sie Beschreibung ein"

                                            ></Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="infoblock[1].link"
                                                component={renderTextField}
                                                label={"Link"}
                                                placeholder="Geben Sie den Link ein"

                                            ></Field>
                                        </Col>
    */}
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="infoblock[1].image"
                                                component={renderImageField}

                                            ></Field>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>


                            <Col lg="3">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="infoblock[2].name"
                                                component={renderTextField}
                                                label={"Name"}
                                                placeholder="Bitte geben Sie einen Namen ein"

                                            ></Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="infoblock[2].image"
                                                component={renderImageField}

                                            ></Field>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>


                            <Col lg="3">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="infoblock[3].name"
                                                component={renderTextField}
                                                label={"Name"}
                                                placeholder="Bitte geben Sie einen Namen ein"

                                            ></Field>
                                        </Col>


                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="infoblock[3].image"
                                                component={renderImageField}

                                            ></Field>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>


                        </Row>
                    </Container>
                </Col>

                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Informationen</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>

                            <Col lg="9">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.phone"
                                                component={renderTextField}
                                                label={"Telefon"}
                                                placeholder="Bitte geben Sie Telefonnr. ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.email"
                                                component={renderTextField}
                                                label={"Email"}
                                                placeholder="Bitte geben Sie E-Mail-Adresse ein"

                                            ></Field>


                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.address"
                                                component={renderTextField}
                                                label={"Adresse"}
                                                placeholder="Bitte geben Sie Adresse ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.coords"
                                                component={renderTextField}
                                                label={"Koordinaten"}
                                                placeholder="Geben Sie die Koordinaten ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.facebook"
                                                component={renderTextField}
                                                label={"Facebook"}
                                                placeholder="Geben Sie den Facebook-Link ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.instagram"
                                                component={renderTextField}
                                                label={"Instagram"}
                                                placeholder="Geben Sie den Instagram-Link ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.footerText"
                                                component={renderTextField}
                                                label={"Fußzeilentext"}
                                                placeholder="Text eingeben"

                                            ></Field>


                                        </Col>



                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.infoBlockTitle"
                                                component={renderTextField}
                                                label={"Titel der Infoblöcke"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.serviceTitle"
                                                component={renderTextField}
                                                label={"Titel der Serviceseite"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.serviceSubtitle"
                                                component={renderTextField}
                                                label={"Untertitel Serviceseite"}
                                                placeholder="Bitte geben Sie einen Untertitel ein"

                                            ></Field>


                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.workingHours[0]"
                                                component={renderTextField}
                                                label={"Öffnungszeiten MO-FR"}
                                                placeholder="Öffnungszeiten"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="config.workingHours[1]"
                                                component={renderTextField}
                                                label={"Öffnungszeiten SA"}
                                                placeholder="Öffnungszeiten"

                                            ></Field>


                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.impressum.title"
                                                component={renderTextField}
                                                label={"Impressum titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.impressum.content"
                                                component={renderHtmlField}
                                            ></Field>
                                        </Col>

                                         <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.datenschutzerklarung.title"
                                                component={renderTextField}
                                                label={"Datenschutzerklärung titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.datenschutzerklarung.content"
                                                component={renderHtmlField}
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.agb.title"
                                                component={renderTextField}
                                                label={"AGB titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.agb.content"
                                                component={renderHtmlField}
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.karriere.title"
                                                component={renderTextField}
                                                label={"Karriere titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="pages.karriere.content"
                                                component={renderHtmlField}
                                            ></Field>
                                        </Col>




                                    </Row>
                                </Container>
                            </Col>


                        </Row>
                    </Container>
                </Col>

                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Über uns</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>

                            <Col lg="9">
                                <Container>
                                    <Row>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="aboutus.title"
                                                component={renderTextField}
                                                label={"Titel"}
                                                placeholder="Bitte geben Sie einen Titel ein"

                                            ></Field>


                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="aboutus.subtitle"
                                                component={renderTextField}
                                                label={"Untertitel"}
                                                placeholder="Bitte geben Sie einen Untertitel ein"

                                            ></Field>


                                        </Col>

                                        <Col lg="6" className="input-wrap">
                                            <Field
                                                name="aboutus.content[0]"
                                                component={renderHtmlField}
                                            ></Field>

                                        </Col>
                                        <Col lg="6" className="input-wrap">
                                            <Field
                                                name="aboutus.content[1]"
                                                component={renderHtmlField}
                                            ></Field>

                                        </Col>


                                    </Row>
                                </Container>
                            </Col>


                        </Row>
                    </Container>
                </Col>


                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Galerie</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>

                            <Col lg="9">
                                <Container>
                                    <Row>
                                    <Col lg="12" className="input-wrap">
                                    <Field
                                        name="images"
                                        component={renderGalleryField}
                                        label={"Bilder"}
                                    ></Field>

                                </Col>

                                    </Row>
                                </Container>
                            </Col>


                        </Row>
                    </Container>
                </Col>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Brands</h3>
                                <h6 className="subtitle">Geben Sie die Informationen ein</h6>

                            </Col>

                            <Col lg="9">
                                <Container>
                                    <Row>
                                    <Col lg="12" className="input-wrap">
                                    <Field
                                        name="brands"
                                        component={renderGalleryField}
                                        label={"Bilder"}
                                    ></Field>

                                </Col>

                                    </Row>
                                </Container>
                            </Col>


                        </Row>
                    </Container>
                </Col>





                <Col lg="12">
                    <button className="button">Speichern</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
