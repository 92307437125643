
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/image.svg';
import { Editor } from '@tinymce/tinymce-react';

class HtmlImage extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = (e) => {
                this.props.onChange({
                    type: 'image',
                    value: e.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    render() {
        return (
                <Editor
                    apiKey="4cy398vgi6uz8lt6opj7pby71lhjbzyxe91wcuwlmyvhhze6"
                    init={{ plugins: 'link table code', height: 280, language:'de', language_url :  'https://admin.showroom.gmbh/langs/de.js'}}
                    value={this.props.value ? this.props.value : ''}
                    onEditorChange={(val) => {

                        this.props.onChange(
                            val
                        )

                    }}
                />




        );
    }
}

export default HtmlImage;